import React from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function Depoimentos(props) {

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1140 },
            items: 3,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1140, min: 540 },
            items: 2,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 540, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    return (

        <div className="container py-5">

            <div className="col text-center text-sm-start mb-5">
                <h2 className="text-uppercase h3">{props.context.title}</h2>
            </div>

            <Carousel
                swipeable={true}
                draggable={false}
                showDots={true}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={5000}
                keyBoardControl={true}
                customTransition="all .5"
                transitionDuration={500}
                containerClass=""
                removeArrowOnDeviceType={[]}
                // deviceType={props.deviceType}
                dotListClass="custom-dot-list-style"
                itemClass=""
            >


                {props.context.content.map (item => (
                    <div className="px-5 text-center">
                        <img className="img-thumbnail rounded-circle border-0" src={item.img} />
                        <h4 className="mt-4">{item.name}</h4>
                        <a href={item.instaLink} target='_blank'>{item.insta}</a>
                        <p className="mt-2">{item.text}</p>
                    </div>
                ))}

            </Carousel>

        </div>

    );
}

export default Depoimentos;
