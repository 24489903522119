// import React from 'react';

function ImgTexto(props) {
    return (

        <div className="container-fluid bg-dark text-white p-0 ">

            <div className="d-none d-lg-block container-fluid" style={{
                backgroundImage: `url(${props.context.bannerFull})`,
                backgroundSize: 'cover',
                backgroundPosition: 'left top',
                // left: '50%'
            }}>

                <div className="container py-5 d-flex justify-content-end" style={{zIndex: 2}}>
                    <div className="col-5 col-lg-6 col-xl-7" style={{minHeight: 500}}>
                        <h2 className="text-uppercase h3">{props.context.title}</h2>
                        <div dangerouslySetInnerHTML={{__html: props.context.text}}/>
                    </div>
                </div>
            </div>

            <div className="d-block d-lg-none pb-5">
            <div className=" card overflow-hidden border-0 rounded-0 text-center">
                <img style={{width: `100%`}} src={props.context.bannerMobile} className=""/>
                <div className="card-img-overlay d-flex flex-column text-center justify-content-end  ms-0 ml-sm-4">
                    <h2 className="text-uppercase h3">{props.context.title}</h2>
                </div>
            </div>
                <div className="container">
                    <div dangerouslySetInnerHTML={{__html: props.context.text}}/>
                </div>
            </div>

        </div>
    );
}

export default ImgTexto;
