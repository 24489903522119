import React, { Component } from 'react';
import {Redirect, withRouter} from "react-router-dom";
import queryString from 'query-string';
import img_capa from '../Cursos/images/capa-curso-beca-milano.jpg'

const formatPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
        return `(${phoneNumber.slice(0, 2)}) ${phoneNumber.slice(2)}`;
    }
    return `(${phoneNumber.slice(0, 2)}) ${phoneNumber.slice(
        2,
        7
    )}-${phoneNumber.slice(7, 11)}`;
};


class Item extends React.Component {
    render() {
        return (
            <div>
                <Redirect to="/lp/obrigado" />
            </div>
        )
    }
}


class Subscribe extends Component {
    params;

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            last: '',
            email: '',
            cel: '',
            list: '12',
            sent: false,
        };

        // this.params = queryString.parse(this.props.location.search)
        // this.setState({list: this.params.list });

    }

    componentDidMount() {


        return <Redirect to='/somewhere'/>;

        // const params = queryString.parse(this.props.location.search);
        // console.log(params.list);
        // this.setState({list2: params.list });
        //
        // console.log('aaaaaaa');
        // console.log(this.state.list2);
        //

    }

    handleSubmit = (event) => {

        event.preventDefault();

        console.log(this.state.name);
        console.log(this.state.email);

        fetch('https://api.sendinblue.com/v3/contacts', {
            method: 'POST',
            headers: {
                'accept': 'application/json',
                'api-key': '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',
                'content-type': 'application/json',
            },
            body: JSON.stringify({
                "email": this.state.email,
                "attributes": {"FIRSTNAME": this.state.name, "LASTNAME": this.state.last, "SMS": this.state.cel},
                "updateEnabled": true,
                "listIds": [54]
            })
        }).then(success => {
            this.setState({ sent: true });
        }).catch(error => {
            console.log(error);
        });

    }

    ChangeName = (event) => {

        const firstname = (event.target.value).split(' ')[0];
        const lastname = (event.target.value).split(' ').slice(-1)[0] ;

        this.setState({name: firstname});

        if (lastname !== undefined && lastname !== firstname) {
            this.setState({last: lastname });
        }

    }
    ChangeEmail = (event) => {
        this.setState({email: event.target.value});
    }
    ChangeSMS = (event) => {
        this.setState({cel: event.target.value});

        const formattedPhoneNumber = formatPhoneNumber(event.target.value);
        const noformattedPhoneNumber = (((formattedPhoneNumber.replace('(','')).replace(')','')).replace(' ','')).replace('-','');

        if (noformattedPhoneNumber.length === 11) {
            this.setState({cel: '+55' + noformattedPhoneNumber});
        }

        // console.log(this.state.cel);

        event.target.value = formattedPhoneNumber;
    }

    ChangeList = (event) => {
        this.setState({list: event.target.value});
    }

    render() {
        const { sent } = this.state;
    return (

        <div id="subscribe" className="container  py-5 ">


            <div className="col text-center text-sm-start mb-3 mb-md-5">

                <h2 className="text-uppercase h3">Inscreva-se e receba informações!</h2>

            </div>

            <div className="row row-cols-1 row-cols-md-2">

                <div className="d-none d-md-block col col-md-4 col-lg-3 col-xl-2 ">
                    <img src={ img_capa } width={200} />
                </div>

            <div className="col col-md-8 col-lg-9 col-xl-10">


                { sent ? <Item /> : null  }
                {sent ? null :
                    <form className="" onSubmit={this.handleSubmit} id="form">

                        <h3 className="mb-3">{this.props.message}</h3>
                        <div className="form-floating mb-2">
                            <input type="text" className="form-control " id="nameInput" placeholder="nome" name="name"
                                   onChange={this.ChangeName} required/>
                            <label>Nome</label>
                        </div>
                        <div className="form-floating mb-2">
                            <input type="email" className="form-control" id="emailInput"
                                   placeholder="nome@exemplo.com.br" name="email" onChange={this.ChangeEmail} required/>
                            <label>Email</label>
                        </div>
                        <div className="form-floating mb-2">
                            <input type="tel" className="form-control" id="celInput" placeholder="cel" name="cel"
                                   onChange={this.ChangeSMS}/>
                            <label className="small">WhatsApp (Não obrigatório)</label>
                        </div>


                        <div className="form-check mb-3">
                            <input className="form-check-input" type="checkbox" value="40"
                                   id="flexCheckDefault" onChange={this.ChangeList} required/>
                            <label className="form-check-label" htmlFor="flexCheckDefault">Concordo com o envio dos meus
                                dados e com a <a className="text-primary"
                                                 href="https://becamilano.com.br/politica-de-privacidade"
                                                 target="_blank">Política de Privacidade</a>.</label>
                        </div>

                        <input type="hidden" className="form-control" id="listInput" placeholder="list" name="list"
                               value="40" required/>

                        <button className="enviar btn btn-info btn-lg">QUERO RECEBER!</button>


                    </form>
                }

        </div>
        </div>


        </div>
    )};
}

export default Subscribe;
