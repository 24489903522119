import svg_oven from '../images/bake.svg';
import svg_vanilla from '../images/diploma.svg';
import svg_buttercream from '../images/mixer.svg';
import svg_pastrybag from '../images/cake.svg';
import svg_chantilly from '../images/chantilly.svg';
import svg_caramelo from '../images/pastry-bag.svg';
import svg_mold from '../images/mold.svg';
import svg_ganaches from '../images/mixer.svg';


const contextTopicos0 = {
    title: 'Highlights do curso',
    aling: 0,
    cta_open: 'seja também um confeiteiro',
    cta: 'Inscreva-se',
    content: [
    {
        id: '1',
        title: '+50 técnicas',
        text: 'Aprenda as principais técnicas de confeitaria e crie uma base sólida para se destacar',
        img: svg_oven,
    },
    {
        id: '2',
        title: 'Certificado',
        text: 'Garanta qualificação profissional com a chef confeiteira de maior destaque do país',
        img: svg_vanilla,
    },
    {
        id: '3',
        title: 'Receitas',
        text: 'Tenha acesso às receitas realizadas pela chef confeiteira em seu atelier',
        img: svg_buttercream,
    },
    {
        id: '4',
        title: '8 bolos',
        text: 'Passo a passo completo de oito bolos clássicos',
        img: svg_pastrybag,
    },
    {
        id: '5',
        title: 'Decoração',
        text: 'Aprenda técnicas de decoração para surpreender',
        img: svg_caramelo,
    },
    {
        id: '6',
        title: 'Montagem',
        text: 'Conheça como padronizar e ter um acabamento perfeito',
        img: svg_chantilly,
    }

]}
export default contextTopicos0
