import React from 'react';
import img_garantia from '../Cursos/images/garantia.png'


function Garantia(props) {
    return (

        <div id="oferta" className="container-fluid py-5 bg-light ">
            <div className="container">

            <div className="row ">

                <div className="col-sm-2  d-flex d-sm-block justify-content-center mb-4">
                    <img className=" img-fluid" src={ img_garantia } />
                </div>

                <div className="col-sm-10  ">
                    <div className="mb-4">
                        <h2 className=" text-uppercase">COMPROMISSO DE SATISFAÇÃO</h2>
                    </div>
                    <p>Garantimos a qualidade do conteúdo, veracidade de informações e relevância de todos os nossos
                        cursos,
                        para você mudar de vez sua vida com a orientação de especialistas no assunto. Caso você descubra
                        em
                        até 7 dias que ele não é ideal para você, devolvemos 100% do valor investido.
                        Meu compromisso, em respeito ao seu dinheiro e ao que você tem de mais valioso: seu
                        tempo
                    </p>
                </div>
            </div>


        </div>
        </div>
    );
}

export default Garantia;
