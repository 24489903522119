import React from 'react';
import img_extra_1 from '../Cursos/images/Extras_CremeChocolateAvelaPastaAvela.jpg'
import img_extra_2 from '../Cursos/images/Extras_GlossarioDeConfeitaria.jpg'
import img_extra_3 from '../Cursos/images/Extras_Receita_BiscoitoAmanteigado.jpg'
import img_extra_4 from '../Cursos/images/Extras_TiposDeChocolate.jpg'
import img_extra_5 from '../Cursos/images/Extra_MassaAmanteigada.jpg'

function Extras(props) {
    return (

        <div id="" className="container-fluid py-5 ">

            <div className="container col text-center text-sm-start mb-4">

                <h2 className="text-uppercase h3">+ Adicionais Exclusivos</h2>

            </div>

            <div className="container">
                <div className=" row row-cols-1 mb-2">

                    <div className="col">
                        <div className="card h-100">
                            <img className="card-img-top" src={img_extra_5} />
                            <div className="card-body">
                                <b>Vídeo Aula</b> - Massa Amanteigada
                            </div>
                        </div>
                    </div>

                </div>



            <div className=" row row-cols-1 row-cols-md-2  row-cols-lg-4 g-2">



                <div className="col">
                <div className="card h-100">
                    <img className="card-img-top" src={img_extra_1} />
                    <div className="card-body">
                    <b>Vídeo Aula + Receita PDF</b> - Creme de Chocolate com Avelã e Pasta de Avelã
                    </div>
                </div>
                </div>


                <div className="col">
                <div className="card h-100">
                    <img className="card-img-top" src={img_extra_2} />
                    <div className="card-body">
                        <b>eBooks</b> - Glossário de Confeitaria
                    </div>
                </div>
                </div>

                <div className="col">
                <div className="card h-100">
                    <img className="card-img-top" src={img_extra_3} />
                    <div className="card-body">
                    <b>Vídeo Aula + Receita PDF</b> - Biscoito Amanteigado
                    </div>
                </div>
                </div>

                <div className="col">
                <div className="card h-100">
                    <img className="card-img-top" src={img_extra_4} />
                    <div className="card-body">
                    <b>eBooks</b> - Tipos de Chocolate
                    </div>
                </div>
                </div>

            </div>

        </div>
        </div>
    );
}

export default Extras;
