import React, {useEffect} from "react";
import logoBeca from '../static/images/becamilano.png'
import img_BannerCursoFull from '../static/images/BecaMilano_Curso01_Banner_Home.jpg';
import img_BannerCursoMobile from '../static/images/BecaMilano_Curso01_Banner_Mobile.jpg';

function Home() {


    return (
        <div>

            <div className="container-fluid p-0" >
                <a href="/cursos/transformando-bolos-classicos/?utm_source=beca&utm_medium=beca&utm_campaign=beca">
                    <img className="d-none d-sm-block img-fluid" src={img_BannerCursoFull} />
                    <img className="d-block d-sm-none img-fluid" src={img_BannerCursoMobile} />
                </a>
            </div>

        </div>
    );
}

export default Home;
