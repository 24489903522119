const contextConteudo = {
    title: 'O QUE VOCÊ VAI APRENDER NO CURSO',
    content: [
    {
        id: '1',
        modulo: 'Bolo Red Velvet Naked Cake',
        text: 'Um dos bolos mais pedidos em festas: Red Velvet! A cor única do bolo Red Velvet com a apresentação em Naked Cake transforma esse bolo em um coringa para qualquer ocasião. A técnica para estrutura e montagem do bolo são os responsáveis por deixar uma apresentação surpreendente.',
        aulas: [
            '<b>Aula 01</b> - Introdução',
            '<b>Aula 02</b> - Massa',
            '<b>Aula 03</b> - Geleia de frutas vermelhas',
            '<b>Aula 04</b> - Cream cheese para recheio e cobertura',
            '<b>Aula 05</b> - Montagem',
        ],
    },
    {
        id: '2',
        modulo: 'Bolo Brigadeiro Crocante',
        text: 'Um clássico em festas, o Bolo de Brigadeiro ganha uma decoração diferenciada e moderna com um toque especial no sabor com a doçura do caramelo crocante. Uma massa de liquidicador prática, leve e fofinha que recebe dois deliciosos recheios: brigadeiro e caramelo.',
        aulas: [
            '<b>Aula 01</b> - Introdução',
            '<b>Aula 02</b> - Massa de chocolate de liquidificador',
            '<b>Aula 03</b> - Calda de açúcar',
            '<b>Aula 04</b> - Caramelo',
            '<b>Aula 05</b> - Brigadeiro em ponto de recheio e cobertura',
            '<b>Aula 06</b> - Montagem',
            '<b>Aula 07</b> - Enrolando brigadeiros',
            '<b>Aula 08</b> - Decoração',
        ],
    },
    {
        id: '3',
        modulo: 'Bolo Abacaxi com coco ',
        text: 'Bolo de abacaxi com coco feito com uma massa delicada de pão de ló e dois recheios deliciosos: doce de abacaxi e um creme perfeito para qualquer tipo de bolo. Uma massa bem molhadinha com calda de açúcar saborizada e decoração com chantilly espatulado. O grande destaque fica por conta das flores feitas com abacaxi desidratado que dão um charme especial na decoração.',
        aulas: [
            '<b>Aula 01</b> - Introdução',
            '<b>Aula 02</b> - Massa de pão de ló',
            '<b>Aula 03</b> - Flores de abacaxi desidratadas',
            '<b>Aula 04</b> - Doce e creme de abacaxi / calda',
            '<b>Aula 05</b> - Montagem',
            '<b>Aula 06</b> - Decoração: Chantilly espatulado',
        ],
    },
    {
        id: '4',
        modulo: 'Bolo de Ameixa com doce de leite Toffee ',
        text: 'A massa amanteigada é uma das mais saborosas e serve de base para o Bolo de ameixa com doce de leite, que recebe um delicioso buttercream saborizado com doce de leite e ameixas em uma decoração que destaca a técnica do efeito drapeado.',
        aulas: [
            '<b>Aula 01</b> - Introdução',
            '<b>Aula 02</b> - Massa amanteigada',
            '<b>Aula 03</b> - Recheios - Purê de ameixa e doce de leite de caramelo toffee',
            '<b>Aula 04</b> - Buttercream',
            '<b>Aula 05</b> - Montagem',
            '<b>Aula 06</b> - Decoração',
        ],
    },
    {
        id: '5',
        modulo: 'Jardim de Limão',
        text: 'O Jardim de Limão é um bolo saboroso para quem gosta da união do sabor doce com um toque cítrico. A massa chiffon com textura leve, suave e macia serve de base para os recheios de curd de limão e Ganache de limão siciliano. Na decoração uma apresentação moderna com buttercream alemão marmorizado que resulta em uma explosão de cores com muita delicadeza.',
        aulas: [
            '<b>Aula 01</b> - Introdução',
            '<b>Aula 02</b> - Massa chiffon de limão',
            '<b>Aula 03</b> - Curd de limão',
            '<b>Aula 04</b> - Ganache de limão siciliano',
            '<b>Aula 05</b> - Buttercream alemão',
            '<b>Aula 06</b> - Montagem',
            '<b>Aula 07</b> - Decoração – Parte 1',
            '<b>Aula 08</b> - Decoração – Parte 2',
        ],
    },
    {
        id: '6',
        modulo: 'Floresta Negra',
        text: 'O Bolo Floresta Negra é um clássico remodelado para surpreender a todos com os recheios de ganache de cereja e chocolate meio amargo. A técnica da Folha de Açúcar é utilizada na decoração e garante um resultado charmoso com um acabamento moderno e profissional. Neste bolo você aprenderá a fazer uma cobertura perfeita de Ganache, com quinas perfeitas e em poucos minutos. Uma técnica exclusiva da chef.',
        aulas: [
            '<b>Aula 01</b> - Introdução',
            '<b>Aula 02</b> - Massa de chocolate',
            '<b>Aula 03</b> - Ganache de cereja',
            '<b>Aula 04</b> - Ganache de chocolate meio amargo',
            '<b>Aula 05</b> - Montagem',
            '<b>Aula 06</b> - Decor – Folha de açúcar',
            '<b>Aula 07</b> - Ganache de blindagem',
            '<b>Aula 08</b> - Decoração',
        ],
    },
    {
        id: '7',
        modulo: 'Reflexo de Morango',
        text: 'O clássico bolo de morango agora tem uma apresentação única, moderna e surpreendente. O sabor delicado da massa genoise recebe o recheio de morango e o creme de confeiteiro.  O destaque fica por conta da decoração feita com método exclusivo, que utiliza a técnica do origami cake e tira de ágar para surpreender.',
        aulas: [
            '<b>Aula 01</b> - Introdução',
            '<b>Aula 02</b> - Massa',
            '<b>Aula 03</b> - Morango picados para recheio',
            '<b>Aula 04</b> - Calda de açúcar',
            '<b>Aula 05</b> - Creme de confeiteiro',
            '<b>Aula 06</b> - Suspiro',
            '<b>Aula 07</b> - Montagem',
            '<b>Aula 08</b> - Ganache de blindagem',
            '<b>Aula 09</b> - Decoração – Parte 1 - Origami Cake',
            '<b>Aula 10</b> - Decoração – Parte 2 - Tira de Ágar',
            '<b>Aula 11</b> - Decoração – Parte 3 – Finalização',
        ],
    },
    {
        id: '8',
        modulo: 'Bolo de Maracujá com chocolate',
        text: 'O Bolo de Maracujá com chocolate é um clássico em casamentos e comemorações especiais.  A combinação perfeita da massa pão de ló de chocolate e o ganache de maracujá trazem uma sensação única de sabores. A decoração é o ponto alto com o ganache marmorizado, que destaca uma charmosa compota de maracujá finalizada com um trabalho de bico delicado no topo do bolo.',
        aulas: [
            '<b>Aula 01</b> - Introdução',
            '<b>Aula 02</b> - Massa de pão de ló de chocolate',
            '<b>Aula 03</b> - Compota de maracujá',
            '<b>Aula 04</b> - Ganache de maracujá',
            '<b>Aula 05</b> - Montagem',
            '<b>Aula 06</b> - Cobertura – ganache de chocolate meio amargo e ganache de chocolate branco',
            '<b>Aula 07</b> - Decoração',
            '<b>Aula 08</b> - Finalização',
        ],
    }
]}
export default contextConteudo
