import React from 'react';
import img_cartoes from '../Cursos/images/bandeiras-cartoes.png'



function Oferta(props) {
    return (

        <div id="oferta" className="container-fluid py-5 bg-light ">

            <div className="container col text-center text-sm-start mb-4">

                <h2 className="text-uppercase h3">{ props.context.title }</h2>

            </div>

            <div className="text-center d-flex justify-content-center">
                <div className="p-5 shadow bg-white rounded-3">

                    <div className="">
                        <h2 className="text-uppercase">Por apenas</h2>
                    </div>

                    <div className="" style={{lineHeight: 1}}>
                    <div className="fw-bold d-inline" style={{fontSize: 40}}>R$ </div>
                    <div className="fw-bold d-inline" style={{fontSize: 120}}>497</div>
                    <div className="mt-5 fw-bold d-inline" style={{fontSize: 40}}>,00</div>
                    </div>

                    <div className="mt-4">
                        <h2 className="text-uppercase">Parcele em até 10X</h2>
                    </div>

                    <div className="mt-4">
                        {props.context.itens &&
                            <ul className="list-group list-group-flush text-start">
                                {props.context.itens.map(item => (
                                    <li className="list-group-item">{item}</li>
                                ))}
                            </ul>
                        }

                        <div className="d-grid mt-4">
                            <a id="click_oferta" className="btn btn-lg btn-info text-uppercase" href={ props.context.cta_link + props.utm} target="_blank">{ props.context.cta }</a>
                        </div>



                    </div>


                </div>


            </div>
            <div className=" text-center mt-4 p-3" >
                <img className="img-fluid " src={img_cartoes} />
            </div>

        </div>
    );
}

export default Oferta;
