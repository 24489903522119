import React from 'react';
import logoBeca from '../static/images/becamilano.png'
import img_BannerObrigado from "../static/images/Beca_Email_Page_Obrigado.jpg";

function Obrigado() {
    return (
        <div>

            <div className="container-fluid p-0" >
                <img width="100%" className="img-fluid" src={img_BannerObrigado} />
            </div>

        </div>
    );
}

export default Obrigado;
