import {useEffect} from "react";

function Banner(props) {


    return (
        <div className="container-fluid bg-light text-black p-0 ">
            <div className="d-none d-md-block container-fluid" style={{
                backgroundImage: `url(${props.context.bannerFull})`,
                backgroundSize: 'cover',
                backgroundPosition: 'right top',
                // left: '50%'
            }}>

                <div className="ms-4 pt-4 pb-4 " style={{zIndex: 2}}>

                    <div className="row">
                        <div className="col-6 d-flex align-items-center" style={{minHeight: 500}}>
                            <div>
                                <h1 className="text-uppercase fw-bold">{props.context.title}</h1>
                                <p className="text-uppercase h3">{props.context.with}</p>
                                <p className="h4">{props.context.subtitle}</p>
                                <div className="mt-4">

                                    { props.open ? (
                                        <a className="btn text-uppercase btn-lg btn-info"
                                           href="#oferta" >{ props.context.cta_open }
                                        </a>

                                    ) : (
                                        <a className="btn text-uppercase btn-lg btn-info" href="#subscribe" >{ props.context.cta }</a>
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            <div className="d-block d-md-none card overflow-hidden border-0 rounded-0 text-center">

                <img style={{width: `100%`}} src={props.context.bannerMobile} className=""/>


                <div className="card-img-overlay d-flex flex-column text-center text-sm-left justify-content-end justify-content-md-center ms-0 ml-sm-4">
                    <div className="row-cols-1 row-cols-md-2">
                        <h1 className="text-uppercase fw-bold">{props.context.title}</h1>
                    </div>

                    <div className="row-cols-1 row-cols-md-2">
                        <p className="text-uppercase h3">{props.context.with}</p>
                    </div>
                    <div className="row-cols-1 row-cols-md-2">
                        <p className="h4">{props.context.subtitle}</p>
                    </div>

                    { props.open ? (
                    <a className="btn btn-lg btn-info text-uppercase py-3 mt-3 " href="#oferta" role="button" >{props.context.cta_open}</a>
                    ) : (
                        <a  className="btn text-uppercase btn-lg btn-info py-3 mt-3" href="#subscribe" >{props.context.cta} </a>
                    )}

                </div>

            </div>


        </div>
    );
}

export default Banner;
