const contextFaq = {
    title: 'Perguntas frequentes',
    content: [
    {
        id: '1',
        modulo: 'Por quanto tempo o curso ficará disponível no meu login?',
        aulas: [
            'Após aprovação do seu pagamento, o curso ficará disponível durante 1 (um) ano.',
        ],
    },
    {
        id: '2',
        modulo: 'Como faço para tirar minhas dúvidas durante o curso?',
        aulas: [
            'Fique tranquilo, você não está sozinho! Ao se inscrever no curso você poderá enviar suas dúvidas pela plataforma do curso ou se preferir pelo email: contato@becamilano.com.br',
        ],
    },
    {
        id: '3',
        modulo: 'O curso tem Certificado?',
        aulas: [
            'Sim! Ao assistir 100% das aulas, você poderá baixar o seu certificado de conclusão do curso.',
        ],
    },
    {
        id: '4',
        modulo: 'Vou precisar de algum recurso específico para ter acesso às aulas?',
        aulas: [
            'Você vai precisar apenas de um computador ou celular com conexão a Internet.',
        ],
    },
    {
        id: '5',
        modulo: 'Qual é o prazo máximo de conclusão do curso?',
        aulas: [
            'Os alunos tem até 1 ano para a conclusão do curso.',
        ],
    },
    {
        id: '6',
        modulo: 'Não encontrou resposta para sua dúvida?',
        aulas: [
            'Não se preocupe! Escreva para: contato@becamilano.com.br',
        ],
    }
]}
export default contextFaq
