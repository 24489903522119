// import React from 'react';

function Deadline(props) {
    return (

        <div className="container-fluid bg-light text-black p-0">

            <div className="bg-danger text-white text-center py-3">
                <h2 className="text-uppercase">Em breve!</h2>
            </div>

        {/*<div className="container py-5 text-center">*/}

        {/*    <h3 className="text-uppercase">Inscrições abertas até:</h3>*/}
        {/*    <h4>31 de outubro</h4>*/}
        {/*    <a className="btn btn-info text-uppercase px-3 py-2 mt-4" href="#oferta">Não deixe para a última hora!</a>*/}
        {/*</div>*/}
        </div>
    );
}

export default Deadline;
