import img_DepoimentoLucas from '../images/lucas-anderi.jpg';
import img_DepoimentoNadja from '../images/nadja-hadad.jpg';
import img_DepoimentoRobson from '../images/robson-jassa.jpg';
import img_DepoimentoLuciana from '../images/luciana_souza.png';
import img_DepoimentoLucielly from '../images/lucielly_ribeiro.png';
import img_DepoimentoTatiana from '../images/tatiana_rodrigues.png';
import img_DepoimentoMarlucia from '../images/marlucia_ferreira.png';


const contextDepoimentos0 = {
    title: 'Depoimentos',
    content: [
        // {
        //     id: '1',
        //     name: 'Lucas Anderi',
        //     text: 'A Beca é uma profissional incrível, perfeccionista em cada detalhe, apaixonada pela confeitaria, tenho certeza que o curso formará grandes confeiteiros.',
        //     insta: '@lucasanderi',
        //     instaLink: 'https://www.instagram.com/lucasanderi',
        //     img: img_DepoimentoLucas,
        // },
        // {
        //     id: '2',
        //     name: 'Nadja Hadad',
        //     text: 'Não tenho palavras para descrever o trabalho da Beca, ela coloca amor em cada bolo que faz com uma criatividade acima da média. Seus bolos são únicos em sabor e decoração e tenho certeza que o curso será um grande aliado para quem busca entrar nesse universo doce da confeitaria.',
        //     insta: '@nadjahaddad',
        //     instaLink: 'https://www.instagram.com/nadjahaddad',
        //     img: img_DepoimentoNadja,
        // },
        // {
        //     id: '3',
        //     name: 'Robson Jassa',
        //     text: 'Uma pessoa simples e com muita sofisticação, essa é minha amiga querida, Beca Milano.',
        //     insta: '@robsonjassa',
        //     instaLink: 'https://www.instagram.com/robsonjassa',
        //     img: img_DepoimentoRobson,
        // },
        {
            id: '4',
            name: 'Luciana de Souza',
            text: 'Muito obrigada por este curso tão maravilhoso onde eu pude aprender tanta coisa importante, que será sempre meu apoio em minha jornada para conseguir ser uma confeiteira.',
            img: img_DepoimentoLuciana,
        },
        {
            id: '5',
            name: 'Lucielly Ribeiro',
            text: 'Que honra poder ser sua aluna nesse curso maravilhoso!!',
            img: img_DepoimentoLucielly,
        },
        {
            id: '6',
            name: 'Tatiana Rodrigues',
            text: 'Curso simplesmente maravilhoso, Beca é uma profissional espetacular. Investimento que vou levar pra vida',
            img: img_DepoimentoTatiana,
        },
        {
            id: '7',
            name: 'Marlucia Ferreira',
            text: 'A minha experiência foi maravilhosa. Técnicas incríveis e fáceis de aplicar na prática. Estou satisfeita com o curso!',
            img: img_DepoimentoMarlucia,
        }

    ]
}
export default contextDepoimentos0
