import React from 'react';

function VideoTexto(props) {
    return (

        <div className="container py-3 py-md-5">


            <div className="row ">
                <div className="col-lg-7 mb-0 mb-sm-0 mb-md-4 mb-lg-0">

                    <iframe className="ratio ratio-16x9"
                            src={`https://player.vimeo.com/video/${props.context.videoLink}`} width="640" height="360"
                            frameBorder="0"
                            allow="autoplay; fullscreen; picture-in-picture" allowFullScreen/>

                </div>

                <div className="col-lg-5">
                    <div className="mb-4">
                        <h2 className="d-none d-lg-block text-uppercase h3">{ props.context.title }</h2>
                        <h2 className="d-block d-lg-none text-uppercase h3 text-center">{ props.context.title }</h2>
                    </div>
                    <p>
                        { props.context.text }
                    </p>
                </div>



            </div>



        </div>
    );
}

export default VideoTexto;
