import React from 'react';

function Video(props) {
    return (


        <div className="container-fluid bg-dark text-light">
        <div className="container py-3 py-md-5">


            <div className="row row-cols-1 ">

                <div className="col text-center text-sm-start mb-4">
                    <h2 className="text-uppercase h3">{props.context.title}</h2>
                </div>

                <div className="col ratio ratio-16x9 ">

                    <iframe className=""
                            src={`https://player.vimeo.com/video/${props.context.videoLink}`} width="1920" height=""
                            frameBorder="0"
                            allow="autoplay; fullscreen; picture-in-picture" allowFullScreen/>
                </div>

            </div>

        </div>
        </div>
    );
}

export default Video;
