import React from 'react';

function Topicos(props) {
    return (
<div className="container-fluid bg-light">
        <div className="container py-5">

            <div className="col text-center text-sm-start mb-5 ">
                <h2 className="text-uppercase h3">{props.context.title}</h2>
            </div>

            <div className="row row-cols-sm-1 row-cols-lg-3">
            {props.context.content.map (item => (
                <div id={item.id}>
                    <div className="col">
                        <div className=" mx-0 mb-4 w-100">

                            {props.context.aling === 1 ? (

                                <div className="d-flex flex-row">
                                    <div class="p-4 border-end">
                                        <img src={item.img} width={50}/>
                                    </div>
                                    <div className="ms-3">
                                        <h4>{item.title}</h4>
                                        <p>{item.text}</p>
                                    </div>
                                </div>

                            ) : (

                                <div className="col text-center w-100">
                                    <div className="d-flex justify-content-center mb-4">
                                        <img src={item.img} width={60}/>
                                    </div>
                                    <h3 className="h5 text-center ">{item.title}</h3>

                                    <p className="text-center">{item.text}</p>

                                </div>

                            )}

                        </div>
                    </div>
                </div>
            ))}
            </div>

            {props.open &&
            <div className="d-md-block d-grid">
                <a className="btn btn-lg btn-info text-uppercase py-3 mt-3 " href="#oferta"
                   role="button">{props.context.cta_open}</a>
            </div>
            }

        </div>
        </div>
    );
}

export default Topicos;
