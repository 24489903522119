import React, {useEffect, useState} from "react";
import {withRouter, useParams } from "react-router-dom";
import Banner from "../components/Banner";
import VideoTexto from "../components/VideoTexto";
import Video from "../components/Video";
import Conteudo from "../components/Conteudo";

import img_bannerFull from "./images/BecaMilano_Curso01_Banner_Full.jpg";
import img_bannerMobile from "./images/BecaMilano_Curso01_Banner_Mobile.jpg";
import img_historiaFull from "./images/BecaMilano_Curso01_Banner_Historia_Full.jpg";
import img_historiaMobile from "./images/BecaMilano_Curso01_Banner_Historia_Mobile.jpg";

import contextConteudo from "./contextApi/conteudo"
import contextFaq from "./contextApi/faq"
import contextTopicos0 from "./contextApi/topicos0"
import contextTopicos1 from "./contextApi/topicos1"
import contextDepoimentos0 from "./contextApi/depoimentos0";


import ImgTexto from "../components/ImgTexto";
import Topicos from "../components/Topicos";
import Subscribe from "../components/Subscribe";
import Depoimentos from "../components/Depoimentos";
import Oferta from "../components/Oferta";
import Countdown, {zeroPad} from 'react-countdown';
import Moment from "moment";
import Extras from "../components/Extras";
import Garantia from "../components/Garantia";

import TagManager from 'react-gtm-module'
import Deadline from "../components/Deadline";

const tagManagerArgs = {
    dataLayer: {
        userId: '001',
        userProject: 'project',
        page: 'home'
    },
    dataLayerName: 'PageDataLayer'
}

const openenv = process.env.REACT_APP_BECAOPEN

const bannerContext1 = {
    bannerFull: img_bannerFull,
    bannerMobile: img_bannerMobile,
    title: 'Bolos para Lucrar e Impressionar',
    subtitle: '',
    with: 'com Beca Milano',
    cta: 'Inscreva-se e receba informações',
    cta_open: 'Compre agora!',
}

const videoTextoContext01 = {
    title: "Quer aprender a fazer bolos para lucrar e impressionar?",
    videoLink: '701346896?h=5567e66822',
    text: 'Quer ter acesso às receitas realizadas por Beca Milano em seu atelier? Quer aprender mais de 50 técnicas de confeitaria para criar novos sabores e decorações surpreendentes para seus bolos? Quer ter em seu currículo um certificado assinado pela profissional mais requisitada do Brasil? Essa é sua chance de fazer parte da primeira turma de confeitaria da chef Beca Milano. Nesse curso, ela traz o passo a passo de oito bolos clássicos, com um conteúdo completo distribuído em 8 módulos com 59 aulas super didáticas e um material de apoio para quem busca empreender.',
}


const videoContext01 = {
    title: "História de vida",
    videoLink: '620226440?h=2df7901bd9'
}


const ImgTextoContext1 = {
    bannerFull: img_historiaFull,
    bannerMobile: img_historiaMobile,
    title: 'Sobre Beca Milano',
    text: '<p>Beca Milano é unanimidade quando o assunto é inovação na confeitaria. Com perfeccionismo em cada detalhe, seus bolos são verdadeiras obras de arte, que carregam além de muito sabor, um visual surpreendente com extremo bom gosto e criatividade.</p><p>A trajetória da chef sempre esteve ligada à gastronomia. Beca formou-se em Farmácia e Bioquímica para trabalhar no desenvolvimento de produtos alimentícios, mas viu que buscava algo a mais. A realização profissional só veio quando decidiu entrar de cabeça no universo da confeitaria. Sua inquietação a levou a fazer inúmeros cursos de Pâtisserie e Boulangerie, especializando-se em técnicas de decoração em açúcar e chocolate.</p><p>Em 2016 seu talento não passou despercebido e foi selecionada para participar do programa “Fábrica de Casamentos” onde criou bolos grandiosos que chamaram a atenção do público e teve seu trabalho reconhecido nacionalmente. Seu profissionalismo e doçura lhe renderam também a oportunidade de ser jurada de um programa que sempre foi fã “Bake Off Brasil- Mão na Massa”, onde imprimiu um estilo único de avaliar com críticas estruturadas e criações surpreendentes.</p>',
}

const ofertaContext1 = {
    title: 'Inscreva-se agora mesmo',
    cta: 'Compre agora!',
    cta_link: 'https://pay.hotmart.com/E69269597B?off=a0kzgnnw',
    itens: [
        '+ de 11 horas de curso',
        'Aprenda mais de 50 técnicas ',
        'Aprenda técnicas Exclusivas da Beca Milano',
        'Fichas Técnicas de todos os Bolos',
        'Material de Apoio',
        'Certificado'
    ]
}



function CursosTransformando() {

    TagManager.dataLayer(tagManagerArgs)

    const [opencart, setOpencart] = useState(false)
    // const [opencart, setOpencart] = useState(false)


    const utm_source = new URLSearchParams(window.location.search).get("utm_source")
    const utm_medium = new URLSearchParams(window.location.search).get("utm_medium")
    const utm_campaign = new URLSearchParams(window.location.search).get("utm_campaign")
    const utm_id = new URLSearchParams(window.location.search).get("utm_id")

    const utm = (
        ( utm_source ? ("&utm_source=" + utm_source) : '' ) +
        ( utm_medium ? ("&utm_medium=" + utm_medium) : '' ) +
        ( utm_campaign ? ("&utm_campaign=" + utm_campaign) : '' ) +
        ( utm_id ? ("&utm_id=" + utm_id) : '' )
    )

    useEffect(() => {
        //document.title = "Transformando Bolos Clássicos - Beca Milano";
        document.title = "Bolos para Lucrar e Impressionar - Beca Milano";


        if (openenv == 'true') {
            setOpencart(true)
        } else {
            setOpencart(false)
        }

    }, []);
    //
    // const renderer = ({ days, hours, minutes, seconds, completed }) => {
    //     if (completed) {
    //         setOpencart(true);
    //         return <div />;
    //     } else {
    //         setOpencart(false);
    //         return <div className=" bg-light py-5" >
    //
    //             <div className="container col text-center mb-2">
    //
    //                 <h2 className="text-uppercase h3">INSCRIÇÕES TERMINAM EM:</h2>
    //
    //             </div>
    //             <div className="d-flex text-center justify-content-center fw-bold">
    //
    //                 <div ><div className="mx-3" style={{fontSize: 55}}>{ zeroPad(days) }</div><p>dia(s)</p></div>
    //                 <div ><div className="mx-3" style={{fontSize: 55}}>{ zeroPad(hours) }</div><p>hora(s)</p></div>
    //                 <div ><div className="mx-3" style={{fontSize: 55}}>{ zeroPad(minutes) }</div><p>minuto(s)</p></div>
    //                 <div ><div className="mx-3" style={{fontSize: 55}}>{ zeroPad(seconds) }</div><p>segundo(s)</p></div>
    //
    //
    //             </div>
    //
    //             <div className="text-center">
    //                 <a className="btn btn-info text-uppercase px-3 py-2 mt-4" href="#oferta">Não deixe para a última hora!</a>
    //             </div>
    //
    //
    //
    //
    //         </div>;
    //     }
    // }

    const startDate = Moment(Date.now());
    const timeEnd = Moment(1648581927000);
    const duration  = Moment.duration(timeEnd.diff(startDate));

    return (
        <div>

            <Banner  context={ bannerContext1 } open={ opencart } />
            {/*{ opencart && <Countdown date={Date.now() + duration}  renderer={renderer} /> }*/}
            { !opencart && <Deadline /> }
            <VideoTexto context={ videoTextoContext01 } />
            <Topicos context={ contextTopicos0 }  open={ opencart } />
            <ImgTexto context={ ImgTextoContext1 } />
            <Video context={ videoContext01 } />
            <Topicos context={ contextTopicos1 }  open={ opencart } />
            <Conteudo context={ contextConteudo } />
            <Extras />
            { opencart && <Oferta context={ ofertaContext1 } utm={utm} /> }
            { opencart && <Garantia /> }
            <Depoimentos context={ contextDepoimentos0 } />
            { !opencart && <Subscribe /> }
            { opencart && <Conteudo context={ contextFaq } /> }

        </div>
    );
}

export default withRouter(CursosTransformando);
