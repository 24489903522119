import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";
import Home from "./views/Home";
import CursosTransformando from "./Cursos/CursoBolosClassicos";
import Obrigado from "./views/Obrigado";

function App() {

    return (

        <Router>
            <Switch>
                <Route path="/" render={ Home } exact/>

                <Route path="/cursos/bolos-para-lucrar-impressionar/:id?" render={ CursosTransformando } exact/>

                <Route path="/cursos/transformando-bolos-classicos/:id?" render={ CursosTransformando } exact/>

                <Route path="/lp/obrigado" render={ Obrigado } exact/>

                <Route  path="/cursos" exact>
                    <Redirect to={{pathname: '/cursos/bolos-para-lucrar-impressionar', state: {status: 301}}}/>
                </Route>

                <Route  path="/lp/" exact>
                    <Redirect to={{pathname: '/cursos/bolos-para-lucrar-impressionar', state: {status: 301}}}/>
                </Route>

            </Switch>
        </Router>
    );
}

export default App;
