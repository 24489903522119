// import React from 'react';

function Conteudo(props) {
    return (

        <div className="container  py-5 ">

            <div className="col text-center text-sm-start mb-4">

                <h2 className="text-uppercase h3">{props.context.title}</h2>

            </div>

            <div className="mx-4 mx-sm-0">

            {props.context.content.map (item => (


                <div id={item.id} className="row mt-2 border-bottom border-secondary border-info ">

                    <button className="d-flex btn" data-bs-toggle="collapse" data-bs-target={`#collapse${ item.id }`}   >
                        <h5 className="text-info " style={{width: 50}}>{ item.id }.</h5> <h5 className="d-inline text-start">{item.modulo}</h5>
                    </button>

                    <div className="w-100" />



                    <div className="row">
                        <div id={`collapse${ item.id }`} className="collapse ms-3 ps-5">

                            {item.text &&
                            <div>
                                <p>{item.text}</p>
                            </div>
                            }

                            <div className="pt-2 pb-4">
                                {item.aulas.map (aula => (
                                    <div dangerouslySetInnerHTML={{__html: aula }}/>
                                ))}
                            </div>
                        </div>
                    </div>

                </div>


            ))}
            </div>

        </div>

    );
}

export default Conteudo;
