import svg_oven from '../images/oven.svg';
import svg_vanilla from '../images/vanilla.svg';
import svg_buttercream from '../images/dough.svg';
import svg_pastrybag from '../images/fountain.svg';
import svg_chantilly from '../images/chantilly.svg';
import svg_caramelo from '../images/caramel.svg';
import svg_mold from '../images/mold.svg';
import svg_ganaches from '../images/chocolate.svg';
import svg_bolos from '../images/cake.svg';
import svg_merengue from '../images/mixer.svg';
import svg_gelly from '../images/jelly.svg';


const contextTopicos1 = {
    title: 'Fundamentos da Confeitaria',
    aling: 1,
    cta_open: 'Inscreva-se agora!',
    cta: 'Inscreva-se',
    content: [
    {
        id: '1',
        title: 'Assamento Correto',
        text: 'Essa etapa é fundamental na confeitaria, por isso a Beca vai dar dicas de como assar um bolo perfeito',
        img: svg_oven,

    },
    {
        id: '2',
        title: 'Baunilha',
        text: 'Tudo sobre a baunilha, além de receita para fazer seu próprio extrato e ter um sabor único em suas receitas',
        img: svg_vanilla,
    },
    {
        id: '3',
        title: 'Buttercream',
        text: 'Tenha acesso as receitas e conheça um pouco mais sobre cada tipo de buttercream',
        img: svg_buttercream,
    },
    {
        id: '4',
        title: 'Caldas',
        text: 'Receitas de caldas e truque para descobrir a quantidade de calda ideal',
        img: svg_pastrybag,
    },
    {
        id: '5',
        title: 'Caramelo',
        text: 'Aprenda as técnicas que envolvem esse preparo e as receitas',
        img: svg_caramelo,
    },
    {
        id: '6',
        title: 'Chantilly',
        text: 'Aprenda a diferença do chantilly de origem animal e vegetal e como usar cada um, além de dicas para não errar no preparo',
        img: svg_chantilly,
    },
    {
        id: '7',
        title: 'Desmoldante caseiro',
        text: 'Receita para untar de maneira bem mais fácil suas formas',
        img: svg_mold,
    },
    {
        id: '8',
        title: 'Ganaches',
        text: 'Aprenda as proporções corretas para cada ponto de textura',
        img: svg_ganaches,
    },
    {
        id: '9',
        title: 'Gelatina X ágar-ágar',
        text: 'Conheça a diferença entre eles e como utilizar em seus preparos',
        img: svg_gelly,
    },
    {
        id: '10',
        title: 'Merengues',
        text: 'A Beca vai ensinar sobre os diferentes tipos desse clássico da confeitaria e as diferenças tanto no preparo, quanto na utilização',
        img: svg_merengue,
    },
    {
        id: '11',
        title: 'Montagem de bolo',
        text: 'O passo a passo correto para um bolo em camadas perfeitas',
        img: svg_bolos,
    }

]}
export default contextTopicos1
